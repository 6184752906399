

























import {
  defineComponent,
  computed
} from '@nuxtjs/composition-api';
import { storeToRefs } from 'pinia';
import { usePartFinderStore } from '~/modules/part-finder/stores/partFinder';
import DropdownBox from './DropdownBox.vue';

interface DropdownOption {
  value: number;
  label: string;
}

export default defineComponent({
  name: 'dropdowns',
  components: {
    DropdownBox
  },
  setup () {
    const store = usePartFinderStore();

    const {
      brands,
      models,
      years,
      brandsLoading,
      modelsLoading,
      yearsLoading,
      selectedBrand,
      selectedModel,
      selectedYear
    } = storeToRefs(store)
    const {
      setBrand,
      setModel
    } = store;

    const brandOptions = computed(() => brands.value.map(brand => {
      return {
        label: brand.name,
        value: brand.brand_id
      }
    }))
    const modelOptions = computed(() => models.value.map(model => {
      return {
        label: model.name,
        value: model.model_id
      }
    }))
    const yearOptions = computed(() => years.value.map(year => {
      return {
        label: year,
        value: year
      }
    }))

    function selectBrand(option: DropdownOption) {
      const chosenBrand = brands.value.find(b => b.brand_id === option.value);
      if (chosenBrand) {
        setBrand(chosenBrand)
      }
    }

    function selectModel(option: DropdownOption) {
      const chosenModel = models.value.find(m => m.model_id === option.value);
      if (chosenModel) {
        setModel(chosenModel);
      }
    }

    function selectYear(option: DropdownOption) {
      const chosenYear = years.value.find(y => y === option.value);
      if (chosenYear) {
        selectedYear.value = chosenYear;
      }
    }

    return {
      selectedBrand,
      selectedModel,
      selectedYear,
      brandsLoading,
      modelsLoading,
      yearsLoading,
      brandOptions,
      modelOptions,
      yearOptions,
      selectBrand,
      selectModel,
      selectYear
    }
  }
})
