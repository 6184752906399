
























import LazyHydrate from 'vue-lazy-hydration';
import { useRoute, defineComponent } from '@nuxtjs/composition-api';
import { useUiState } from '@wemade-vsf/composables';
import AppHeader from 'components/theme/Alternate/AppHeader.vue';
import IconSprite from 'components/theme/General/IconSprite.vue';
import LoadWhenVisible from 'components/theme/utils/LoadWhenVisible.vue';

export default defineComponent({
  name: 'DefaultLayout',

  components: {
    LoadWhenVisible,
    LazyHydrate,
    AppHeader,
    IconSprite,
    AppFooter: () => import(/* webpackPrefetch: true */ 'components/theme/AppFooter.vue'),
    CartSidebar: () => import(/* webpackPrefetch: true */ 'components/checkout/CartSidebar.vue'),
    LoginModal: () => import(/* webpackPrefetch: true */ 'components/customer/LoginModal/LoginModal.vue'),
    Notification: () => import(/* webpackPrefetch: true */ 'components/theme/Notification.vue'),
  },

  setup() {
    const route = useRoute();
    const {
      isCartSidebarOpen, isLoginModalOpen, toggleLoginModal,
    } = useUiState();

    return {
      isCartSidebarOpen,
      isLoginModalOpen,
      toggleLoginModal,
      route,
    };
  }
});
