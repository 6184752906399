const moduleOptions = JSON.parse(`{"allowRegistration":true,"registrationPage":"bli-kunde"}`);
var plugin_default = (context, inject) => {
  var _a;
  let wm = (_a = context.$wm) != null ? _a : {};
  wm.customer = moduleOptions;
  inject("wm", wm);
};
export {
  plugin_default as default
};
