const moduleOptions = JSON.parse(`{"canUpdateBillingAddress":false,"canUpdateDefaultBilling":false,"canUpdateShippingAddress":true,"canUpdateDefaultShipping":true,"canEditAddresses":true,"canAddAddresses":true,"canEditAccountInfo":true}`);
var plugin_default = (context, inject) => {
  var _a;
  let wm = (_a = context.$wm) != null ? _a : {};
  wm.company = moduleOptions;
  inject("wm", wm);
};
export {
  plugin_default as default
};
