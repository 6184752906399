import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _2bcf6574 = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _c8d03b18 = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _2b02a253 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _f370131c = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _53d17b40 = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _d59d3d26 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _3a0651db = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _51c22997 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _6191d31b = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _ddc85232 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _bb37d25a = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _0f6e30f8 = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _c4105a20 = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _d39f17bc = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _ffdcdd58 = () => interopDefault(import('../_templates/pages/wishlist/Wishlist.vue' /* webpackChunkName: "" */))
const _4bfc6b93 = () => interopDefault(import('../modules/part-finder/pages/Brands.vue' /* webpackChunkName: "" */))
const _3a81b2b4 = () => interopDefault(import('../_templates/pages/quickorder/QuickOrder.vue' /* webpackChunkName: "" */))
const _3882dcf4 = () => interopDefault(import('../_templates/pages/search/Search.vue' /* webpackChunkName: "" */))
const _0b0c8b94 = () => interopDefault(import('../_templates/pages/payment-svea/Checkout.vue' /* webpackChunkName: "" */))
const _0b6ea3f8 = () => interopDefault(import('../pages/catalog/Category.vue' /* webpackChunkName: "pages/catalog/Category" */))
const _92ebc64e = () => interopDefault(import('../pages/catalog/Product.vue' /* webpackChunkName: "pages/catalog/Product" */))
const _5526a5d4 = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _44aac12e = () => interopDefault(import('../_templates/pages/payment-svea/CheckoutConfirmation.vue' /* webpackChunkName: "" */))
const _cdd77798 = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _77893131 = () => interopDefault(import('../modules/part-finder/pages/Models.vue' /* webpackChunkName: "" */))
const _37cf2245 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _0b67a178 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _4258a269 = () => interopDefault(import('../modules/part-finder/pages/Parts.vue' /* webpackChunkName: "" */))
const _23a62ec0 = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aktuelt",
    component: _2bcf6574,
    name: "blog___nb_no___default"
  }, {
    path: "/checkout",
    component: _c8d03b18,
    name: "checkout___nb_no___default",
    children: [{
      path: "bestilling",
      component: _2b02a253,
      name: "checkout-order___nb_no___default"
    }, {
      path: "konto",
      component: _f370131c,
      name: "checkout-account___nb_no___default"
    }, {
      path: "takk",
      component: _53d17b40,
      name: "checkout-thank-you___nb_no___default"
    }]
  }, {
    path: "/customer",
    component: _d59d3d26,
    meta: {"titleLabel":"Account"},
    name: "customer___nb_no___default",
    children: [{
      path: "addresses-details",
      component: _3a0651db,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___nb_no___default"
    }, {
      path: "my-profile",
      component: _51c22997,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___nb_no___default"
    }, {
      path: "order-history",
      component: _6191d31b,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___nb_no___default"
    }, {
      path: "addresses-details/create",
      component: _ddc85232,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___nb_no___default"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _bb37d25a,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___nb_no___default"
    }, {
      path: "order-history/:orderId",
      component: _0f6e30f8,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___nb_no___default"
    }]
  }, {
    path: "/en_uk",
    component: _c4105a20,
    name: "home___en_uk"
  }, {
    path: "/faq.html",
    component: _d39f17bc,
    name: "faq___nb_no___default"
  }, {
    path: "/nb_no",
    component: _c4105a20,
    name: "home___nb_no"
  }, {
    path: "/onskeliste",
    component: _ffdcdd58,
    name: "wishlist___nb_no___default"
  }, {
    path: "/parts",
    component: _4bfc6b93,
    name: "part-finder___nb_no___default"
  }, {
    path: "/quickorder",
    component: _3a81b2b4,
    name: "quickorder___nb_no___default"
  }, {
    path: "/search",
    component: _3882dcf4,
    name: "search___nb_no___default"
  }, {
    path: "/sveacheckout",
    component: _0b0c8b94,
    name: "sveaCheckout___nb_no___default"
  }, {
    path: "/catalog/Category",
    component: _0b6ea3f8,
    name: "catalog-Category___nb_no___default"
  }, {
    path: "/catalog/Product",
    component: _92ebc64e,
    name: "catalog-Product___nb_no___default"
  }, {
    path: "/customer/reset-password",
    component: _5526a5d4,
    alias: "/customer/account/createPassword",
    name: "reset-password___nb_no___default"
  }, {
    path: "/en_uk/aktuelt",
    component: _2bcf6574,
    name: "blog___en_uk"
  }, {
    path: "/en_uk/checkout",
    component: _c8d03b18,
    name: "checkout___en_uk",
    children: [{
      path: "bestilling",
      component: _2b02a253,
      name: "checkout-order___en_uk"
    }, {
      path: "konto",
      component: _f370131c,
      name: "checkout-account___en_uk"
    }, {
      path: "takk",
      component: _53d17b40,
      name: "checkout-thank-you___en_uk"
    }]
  }, {
    path: "/en_uk/customer",
    component: _d59d3d26,
    meta: {"titleLabel":"Account"},
    name: "customer___en_uk",
    children: [{
      path: "addresses-details",
      component: _3a0651db,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___en_uk"
    }, {
      path: "my-profile",
      component: _51c22997,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___en_uk"
    }, {
      path: "order-history",
      component: _6191d31b,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___en_uk"
    }, {
      path: "addresses-details/create",
      component: _ddc85232,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___en_uk"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _bb37d25a,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___en_uk"
    }, {
      path: "order-history/:orderId",
      component: _0f6e30f8,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___en_uk"
    }]
  }, {
    path: "/en_uk/faq.html",
    component: _d39f17bc,
    name: "faq___en_uk"
  }, {
    path: "/en_uk/onskeliste",
    component: _ffdcdd58,
    name: "wishlist___en_uk"
  }, {
    path: "/en_uk/parts",
    component: _4bfc6b93,
    name: "part-finder___en_uk"
  }, {
    path: "/en_uk/quickorder",
    component: _3a81b2b4,
    name: "quickorder___en_uk"
  }, {
    path: "/en_uk/search",
    component: _3882dcf4,
    name: "search___en_uk"
  }, {
    path: "/en_uk/sveacheckout",
    component: _0b0c8b94,
    name: "sveaCheckout___en_uk"
  }, {
    path: "/nb_no/aktuelt",
    component: _2bcf6574,
    name: "blog___nb_no"
  }, {
    path: "/nb_no/checkout",
    component: _c8d03b18,
    name: "checkout___nb_no",
    children: [{
      path: "bestilling",
      component: _2b02a253,
      name: "checkout-order___nb_no"
    }, {
      path: "konto",
      component: _f370131c,
      name: "checkout-account___nb_no"
    }, {
      path: "takk",
      component: _53d17b40,
      name: "checkout-thank-you___nb_no"
    }]
  }, {
    path: "/nb_no/customer",
    component: _d59d3d26,
    meta: {"titleLabel":"Account"},
    name: "customer___nb_no",
    children: [{
      path: "addresses-details",
      component: _3a0651db,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details___nb_no"
    }, {
      path: "my-profile",
      component: _51c22997,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile___nb_no"
    }, {
      path: "order-history",
      component: _6191d31b,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history___nb_no"
    }, {
      path: "addresses-details/create",
      component: _ddc85232,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new___nb_no"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _bb37d25a,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit___nb_no"
    }, {
      path: "order-history/:orderId",
      component: _0f6e30f8,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order___nb_no"
    }]
  }, {
    path: "/nb_no/faq.html",
    component: _d39f17bc,
    name: "faq___nb_no"
  }, {
    path: "/nb_no/onskeliste",
    component: _ffdcdd58,
    name: "wishlist___nb_no"
  }, {
    path: "/nb_no/parts",
    component: _4bfc6b93,
    name: "part-finder___nb_no"
  }, {
    path: "/nb_no/quickorder",
    component: _3a81b2b4,
    name: "quickorder___nb_no"
  }, {
    path: "/nb_no/search",
    component: _3882dcf4,
    name: "search___nb_no"
  }, {
    path: "/nb_no/sveacheckout",
    component: _0b0c8b94,
    name: "sveaCheckout___nb_no"
  }, {
    path: "/sveacheckout/confirmation",
    component: _44aac12e,
    name: "sveaCheckoutConfirmation___nb_no___default"
  }, {
    path: "/en_uk/catalog/Category",
    component: _0b6ea3f8,
    name: "catalog-Category___en_uk"
  }, {
    path: "/en_uk/catalog/Product",
    component: _92ebc64e,
    name: "catalog-Product___en_uk"
  }, {
    path: "/en_uk/customer/reset-password",
    component: _5526a5d4,
    alias: "/customer/account/createPassword",
    name: "reset-password___en_uk"
  }, {
    path: "/en_uk/sveacheckout/confirmation",
    component: _44aac12e,
    name: "sveaCheckoutConfirmation___en_uk"
  }, {
    path: "/nb_no/catalog/Category",
    component: _0b6ea3f8,
    name: "catalog-Category___nb_no"
  }, {
    path: "/nb_no/catalog/Product",
    component: _92ebc64e,
    name: "catalog-Product___nb_no"
  }, {
    path: "/nb_no/customer/reset-password",
    component: _5526a5d4,
    alias: "/customer/account/createPassword",
    name: "reset-password___nb_no"
  }, {
    path: "/nb_no/sveacheckout/confirmation",
    component: _44aac12e,
    name: "sveaCheckoutConfirmation___nb_no"
  }, {
    path: "/en_uk/aktuelt/:slug+",
    component: _cdd77798,
    name: "blogPost___en_uk"
  }, {
    path: "/en_uk/parts/:brand",
    component: _77893131,
    name: "part-finder-models___en_uk"
  }, {
    path: "/nb_no/aktuelt/:slug+",
    component: _cdd77798,
    name: "blogPost___nb_no"
  }, {
    path: "/nb_no/parts/:brand",
    component: _77893131,
    name: "part-finder-models___nb_no"
  }, {
    path: "/en_uk/collection/:slug([^/.]+).html",
    component: _37cf2245,
    name: "attributeCollection___en_uk"
  }, {
    path: "/nb_no/collection/:slug([^/.]+).html",
    component: _37cf2245,
    name: "attributeCollection___nb_no"
  }, {
    path: "/en_uk/collection/:collection_slug/:slug+.html",
    component: _0b67a178,
    name: "attributeCollectionItem___en_uk"
  }, {
    path: "/nb_no/collection/:collection_slug/:slug+.html",
    component: _0b67a178,
    name: "attributeCollectionItem___nb_no"
  }, {
    path: "/en_uk/parts/:brand/:model/:year?",
    component: _4258a269,
    name: "part-finder-parts___en_uk"
  }, {
    path: "/nb_no/parts/:brand/:model/:year?",
    component: _4258a269,
    name: "part-finder-parts___nb_no"
  }, {
    path: "/aktuelt/:slug+",
    component: _cdd77798,
    name: "blogPost___nb_no___default"
  }, {
    path: "/en_uk/:slug+",
    component: _23a62ec0,
    name: "page___en_uk"
  }, {
    path: "/nb_no/:slug+",
    component: _23a62ec0,
    name: "page___nb_no"
  }, {
    path: "/parts/:brand",
    component: _77893131,
    name: "part-finder-models___nb_no___default"
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _37cf2245,
    name: "attributeCollection___nb_no___default"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _0b67a178,
    name: "attributeCollectionItem___nb_no___default"
  }, {
    path: "/parts/:brand/:model/:year?",
    component: _4258a269,
    name: "part-finder-parts___nb_no___default"
  }, {
    path: "/",
    component: _c4105a20,
    name: "home___nb_no___default"
  }, {
    path: "/:slug+",
    component: _23a62ec0,
    name: "page___nb_no___default"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
