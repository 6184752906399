import { defineStore } from "pinia";
import { useContext, ref, Ref, readonly } from '@nuxtjs/composition-api';
import { Logger } from '@wemade-vsf/core'; 
import type { Brand, Model } from '../types';

interface PartFinderError {
  loadBrands: Error | null;
  loadModels: Error | null;
  loadYears: Error | null;
}

interface PartFinderStoreInterface {
  brands: Ref<Array<Brand>>;
  models: Ref<Array<Model>>;
  years: Ref<Array<number>>;
  selectedBrand: Ref<Brand | null>;
  selectedModel: Ref<Model | null>;
  selectedYear: Ref<number | null>;

  brandsLoading: Readonly<Ref<boolean>>;
  modelsLoading: Readonly<Ref<boolean>>;
  yearsLoading: Readonly<Ref<boolean>>;

  error: Readonly<Ref<PartFinderError>>;

  setBrand(brand: Brand): void;
  setModel(model: Model): void;

  loadBrands(page?: number): Promise<void>;
  loadModels(page?: number): Promise<void>;
  loadYears(page?: number): Promise<void>;

  reset(): void;
}

export const usePartFinderStore = defineStore('partFinder', (): PartFinderStoreInterface => {
  const { app } = useContext();

  const brands = ref<Array<Brand>>([]);
  const models = ref<Array<Model>>([]);
  const years = ref<Array<number>>([]);
  const selectedBrand = ref<Brand | null>(null);
  const selectedModel = ref<Model | null>(null);
  const selectedYear = ref<number | null>(null);

  const brandsLoading = ref<boolean>(false);
  const modelsLoading = ref<boolean>(false);
  const yearsLoading = ref<boolean>(false);

  const error = ref<PartFinderError>({
    loadBrands: null,
    loadModels: null,
    loadYears: null
  })

  async function loadBrands(page: number = 1): Promise<void> {
    Logger.debug('UsePartFinder/loadBrands');
    brandsLoading.value = true;
    try {
      const { data } = await app.$vsf.$magento.api.pfBrands({
        pageSize: 300,
        currentPage: page
      });
      const result = data?.WmPartFinderBrands?.items
        ? data.WmPartFinderBrands.items.sort((a, b) => a.name > b.name ? 1 : -1)
        : [];

      brands.value = page === 1 ? result : [...brands.value, ...result];
      
      if (data?.WmPartFinderBrands.total_count > brands.value.length) {
        await loadBrands(page + 1);
      }
      error.value.loadBrands = null
    } catch (err) {
      Logger.error('UsePartFinder/loadBrands', err);
      error.value.loadBrands = err
    } finally {
      brandsLoading.value = false
    }
  }

  async function loadModels(page: number = 1): Promise<void> {
    Logger.debug('UsePartFinder/loadModels', selectedBrand.value);
    if (!selectedBrand.value) return;
    try {
      modelsLoading.value = true;
      const { data } = await app.$vsf.$magento.api.pfModels({
        pageSize: 300,
        currentPage: page,
        filter: { brand_id: { eq: `${selectedBrand.value.brand_id}` } } 
      });
      const result = data?.WmPartFinderModels?.items ?? [];

      models.value = page === 1 ? result : [...models.value, ...result];
      if (data?.WmPartFinderModels?.total_count > models.value.length) {
        await loadModels(page + 1);
      }
      error.value.loadModels = null;
    } catch (err) {
      Logger.error('UsePartFinder/loadModels', err)
      error.value.loadModels = err;
    } finally {
      modelsLoading.value = false;
    }
  }

  async function loadYears(page: number = 1): Promise<void> {
    Logger.debug('UsePartFinder/loadYears', selectedBrand.value);
    if (!selectedBrand.value || !selectedModel.value) return;
    try {
      yearsLoading.value = true;
      const { data } = await app.$vsf.$magento.api.pfYears({ pageSize: 300, currentPage: page, filter: { brand_id: { eq: `${selectedBrand.value.brand_id}` }, model_id: { eq: `${selectedModel.value.model_id}` } } });
      const result = data?.WmPartFinderYears?.items ?? [];
      years.value = page === 1 ? result : [...years.value, ...result];
      if (data?.WmPartFinderYears?.total_count > years.value.length) {
        await loadYears(page + 1)
      }
      error.value.loadYears = null;
    } catch (err) {
      Logger.error('UsePartFinder/loadYears', err)
      error.value.loadYears = err;
    } finally {
      yearsLoading.value = false;
    }
  }

  async function setBrand(brand: Brand) {
    if (selectedBrand.value && selectedBrand.value.brand_id === brand.brand_id) return;
    selectedBrand.value = brand;
    selectedModel.value = null;
    selectedYear.value = null;
    years.value = [];
    models.value = [];
    await loadModels();
  }

  async function setModel(model: Model) {
    if (selectedModel.value && selectedModel.value.model_id === model.model_id) return;
    selectedYear.value = null;
    years.value = [];
    selectedModel.value = model;
    await loadYears();
  }

  function reset() {
    selectedYear.value = null;
    selectedModel.value = null;
    selectedBrand.value = null;
    models.value = [];
    years.value = [];
  }

  return {
    brands,
    models,
    years,

    selectedBrand,
    selectedModel,
    selectedYear,

    brandsLoading: readonly(brandsLoading),
    modelsLoading: readonly(modelsLoading),
    yearsLoading: readonly(yearsLoading),

    error: readonly(error),

    setBrand,
    setModel,

    loadBrands,
    loadModels,
    loadYears,

    reset
  }
})