



















import {
  defineComponent,
  ref,
  onMounted,
  nextTick,
  computed
} from '@nuxtjs/composition-api';
import PbSlide from 'components/theme/Content/Magento/PbSlide.vue';
import Glide from '@glidejs/glide';
export default defineComponent({
  name: 'PbSlider',
  props: {
    fade: {
      type: Boolean,
      default: false
    },
    autoplay: {
      type: Boolean,
      default: false
    },
    autoPlaySpeed: {
      type: Number,
      default: 4000
    },
    infiniteLoop: {
      type: Boolean,
      default: false
    },
    showArrows: {
      type: Boolean,
      default: false
    },
    showDots: {
      type: Boolean,
      default: true
    }
  },
  components: {
    'pb-slide': PbSlide
  },
  setup (props) {
    const glideElm = ref(null);
    const slides = ref<HTMLElement | null>(null);
    const numSlides = computed(() => slides.value?.childNodes?.length || 0)
    let glide;

    const settings = {
      perView: 1,
      autoplay: props.autoplay ? props.autoPlaySpeed : false,
      type: props.infiniteLoop ? 'carousel' : 'slider'
    }

    onMounted(() => {
      nextTick(() => {
        if (!glideElm.value) return
        glide = new Glide(glideElm.value, settings);
        glide.mount();
      })
    });

    function go(nav) {
      if (!glide) return
      glide.go(nav)
    }

    return {
      glideElm,
      slides,
      numSlides,
      go
    }
  }
})
